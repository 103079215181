@media only screen and (max-width: 600px) {
  body {
    background-color: #ffffff;
  }

  .mobileRecom {
    display: block;
  }

  h4 {
    font-size: 16px !important;
  }

  h5 {
    font-size: 15px !important;
  }

  p {
    font-size: 14px;
  }

  small {
    font-size: 12px;
    font-weight: 500;
  }

  .fs-3 {
    font-size: 16px !important;
  }

  .fs-6 {
    font-size: 11px;
  }

  .moboff {
    display: none;
  }

  .mobc {
    display: block;
  }

  .product_body {
    padding: 0px !important;
  }

  .catbottom {
    height: 110px;
    background-image: url("./image/icons/cb.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
  }

  .accbottom {
    height: 120px;
    background-image: url("./image/icons/accb.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .btn_abs {
    position: fixed;
    background-color: #004f98;
    z-index: 1;
    bottom: 9%;
    right: 4%;
    border-radius: 100px;
    font-size: 14px;
    padding: 13px 10px 13px 10px;
  }

  .btn:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  .animate__animated.animate__slideInUp {
    --animate-duration: 0.4s;
  }

  .addcartModal {
    width: 100%;
    margin: 0;
    position: fixed;
    top: auto;
    right: auto;
    left: auto;
    bottom: 0;
  }

  .addcartModalC {
    border-radius: 0px;
    border: none;
  }

  .addcartModal2 {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .addcartModalC2 {
    border-radius: 0px;
    border: none;
  }

  .addCartB {
    width: 100%;
    background-color: #004f98;
  }

  .navbar {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #f8ecec !important;
  }

  .navbar-logo-1 {
    width: 7rem;
  }

  .nabtav {
    border-radius: 10px;
    height: auto;
    box-shadow: none;
    background-image: linear-gradient(to bottom,
        rgb(255, 254, 241),
        rgb(221, 233, 233));
  }

  .navlinkc2 {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .navcname {
    padding-top: 10px;
    font-size: 10px;
  }

  .nav-logo-2 {
    height: 25px;
    object-fit: contain;
  }

  .navbar-bottom {
    height: 55px;
    display: block;
    background: rgb(228, 169, 71);
    background: linear-gradient(90deg,
        rgba(228, 169, 71, 1) 0%,
        rgba(168, 161, 14, 1) 53%,
        rgba(44, 190, 164, 1) 100%);
  }

  .chatlocation {
    position: fixed;
    right: 2%;
    bottom: 10%;
  }

  .bottomcart {
    font-size: 16px;
    color: white;
    font-weight: 600;
    border-right: 2px solid #ffffff;
  }

  .navbicon {
    height: 16px;
    object-fit: contain;
  }

  .home-car {
    height: 19vh;
  }

  .popcarditem {
    width: 40%;
  }

  .feature1 {
    height: 90px;
    background-image: url("./image/icons/testfeature.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
  }

  .res-cont {
    font-size: 25px !important;
    padding-top: 20px;
    text-align: center;

  }

  .feature2 {
    height: 90px;
    background-image: url("./image/icons/testfeature2.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
  }

  .popitem {
    background-image: none;
    height: 220px;
    border-radius: 10px;
  }

  .productcard2 {
    border-radius: 8px;
    border: none;
    height: 160px;
    border: 1px dashed white;
  }

  .card-img-top2 {
    height: 100px;
    width: 100%;
    margin-bottom: 5px;
    border-radius: 5px;
    object-fit: cover;
  }

  .dif-text {
    color: #f68b1f;
    font-size: 24px;
    line-height: 1.2;
  }

  .dif-text2 {
    color: #006d6d;
    font-size: 24px;
    line-height: 1.2;
  }

  .hpcart2 {
    width: 50%;
  }

  .hpcart {
    width: 50%;
  }

  .productcard {
    border: none;
    height: 275px;
  }

  .productcard:hover {
    box-shadow: none;
  }

  .card-img-top {
    height: 150px;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    object-fit: cover;
  }

  .prname {
    font-size: 12px;
    color: #565656;
  }

  .text-price {
    color: #363636;
    font-weight: 400;
    font-size: 14px;
  }

  .supplied {
    font-size: 8px;
    font-weight: 500;
    font-style: italic;
    color: #000f38;
    padding: 5px;
    border-radius: 5px;
    background-color: #01103934;
    width: 80%;
  }

  .promo1 {
    height: 60px;
    border-radius: 0px;
    object-fit: cover;
    width: 100%;
  }

  .promo2 {
    height: 50px;
    border-radius: 5px;
    object-fit: cover;
    width: 100%;
  }

  .moreitems {
    background-color: #eef4f5;
  }

  .user-btn-logo {
    width: 1rem;
    object-fit: contain;
  }

  .navusername {
    font-size: 12px;
    font-weight: 300;
  }

  .home-search {
    border: none;
    height: 2.2rem;
    background-color: transparent;
  }

  .mobsearch {
    font-size: 14px !important;
    color: #777777;
  }

  .searchboxh {
    height: 3.3rem;
    background-image: url("./image/icons/catback.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .searchboxm {
    height: 3.6rem;
    background-image: url("./image/icons/catback.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .searchboxm2 {
    height: 4.8rem;
    background-image: url("./image/icons/catback.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .categorypr {
    background-color: transparent;
    border-radius: 0;
  }

  .cat-text-m {
    font-size: 16px;
    border-radius: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #eef4f5;
    border: 1px dashed #004f98;
    font-weight: 550;
    color: #004f98;
  }

  .mobilecatbg {
    background-color: #eef4f5;
  }

  .webicon {
    height: 20px;
    object-fit: contain;
  }

  .webiconsm {
    height: 16px;
    object-fit: contain;
  }

  .webiconBL {
    height: 25px;
    object-fit: contain;
  }

  .icon-sm {
    width: 1.5rem;
    height: 1.5rem;
    border: none;
  }

  .catnamemob {
    font-size: 15px;
    font-weight: 600;
    color: #363636;
  }

  .profilemob {
    background-image: url("./image/icons/catback.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 85vh;
  }

  .mobfont1 {
    font-size: 14px;
  }

  .hpcart3 {
    width: 50%;
  }

  .productcard3 {
    border-radius: 0px;
    border: none;
    height: 300px;
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 4px 0px;
    margin-bottom: 10px;
  }

  .productcard3:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .dif-text {
    color: #f68b1f;
    font-size: 20px;
    line-height: 1.7;
  }

  .dif-text2 {
    color: #006d6d;
    font-size: 20px;
    line-height: 1.7;
  }

  .sdealfont {
    font-size: 18px;
    font-weight: 600;
  }

  .noproduct {
    height: 8rem;
  }

  .pprprice2 {
    font-size: 16px;
    color: rgb(49, 49, 49);
    font-weight: 550;
  }

  .btn-edit-profile {
    background-color: rgba(255, 255, 255, 0);
    border-radius: 0px;
    color: #363636;
    text-decoration: underline;
  }

  .btn-edit-profile:hover {
    background-color: rgba(255, 255, 255, 0);
    border-radius: 0px;
    color: #363636;
  }

  .btn.btn-edit-profile:focus {
    outline: none;
    border: white !important;
  }

  .btn-edit-address {
    background-color: rgba(220, 224, 255, 0.432);
    border-radius: 10px;
    color: #363636;
    border: 1px solid #00133d;
  }

  .btn-edit-address:focus {
    border: 1px solid #00133d !important;
  }

  .btn-edit-address:hover {
    background-color: rgba(220, 224, 255, 0.432);
    border-radius: 10px;
    color: #363636;
    border: 1px solid #00133d;
  }

  .review-btn {
    background-color: white;
    border-radius: 5px;
    font-size: 14px;
  }

  .star-top {
    font-size: 14px;
  }

  .sborder {
    background-color: #004f98;
    color: white;
  }

  .review-success-btn {
    margin-top: 20px;
    margin-left: 0px;
    background-color: #004f98;
    color: white;
  }

  .review-success-btn:hover {
    background-color: #00133d;
    color: white;
  }

  .catpr {
    width: 100%;
  }

  .order-img {
    width: 6.8rem;
    height: 6.8rem;
    object-fit: cover;
  }

  .orderedplist {
    border-radius: 0px;
    border: 1px dashed #c2c2c2;
  }

  .review_btn {
    height: 2rem;
    font-size: 14px;
  }

  .cart-pro-name2 {
    font-size: 12px;
  }

  .fs-5 {
    font-size: 16px !important;
  }

  .f-c-acc {
    text-decoration: underline;
    font-size: 14px;
  }

  .brandl {
    height: 30px;
    object-fit: contain;
  }

  .brandlf {
    height: 25px;
    object-fit: contain;
    margin-bottom: 10px;
  }

  .seeall {
    background-color: #ffee00;
    border-radius: 5px;
    font-size: 10px;
    text-decoration: none;
    height: 1.7rem;
    color: #262626;
    font-weight: 400;
  }

  .carousel-indicators>button {
    margin-bottom: -20px !important;
    border-radius: 50%;
    width: 8px !important;
    height: 8px !important;
  }

  .badge-cart {
    border-radius: 50%;
    height: 1.2rem;
    width: 1.2rem;
    font-size: 0.6rem;
    margin-top: -10px;
    margin-left: -10px;
  }

  .userbtn {
    background-color: #f5e2e2;
    height: 2rem;
  }

  .mobtext {
    font-size: 12px;
  }

  .catimg {
    height: 15px;
    object-fit: contain;
    margin-right: 10px;
  }

  .navtexts {
    font-size: 13px;
    color: #565656;
    text-decoration: underline;
  }

  .orderc {
    height: 200px;
  }

  .sboxm {
    width: 100%;
    height: 100vh;
    left: 0px;
    right: 0px;
    position: absolute;
    top: 55px;
    overflow: auto;
    border-radius: 0;
  }

  .footicon {
    height: 30px;
    object-fit: contain;
    margin: 5px;
  }

  .branditem {
    width: 25%;
  }
}